import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import {
  getConfig,
  fetchNode,
  extractMeta,
  extractMenus,
  extractBlocks,
} from "../../utils/data"

export const pageQuery = graphql`
  query {
    allBlockContentBasic {
      edges {
        node {
          body {
            processed
          }
          info
        }
      }
    }
    allSite {
      edges {
        node {
          siteMetadata {
            author
            description
            siteUrl
            title
          }
        }
      }
    }
    allMenuLinkContentMenuLinkContent {
      edges {
        node {
          menu_name
          enabled
          title
          url
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { meta } = extractMeta(props)
  const { menus } = extractMenus(props)
  const { blocks } = extractBlocks(props)

  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState({})

  React.useEffect(() => {
    ;(async () => {
      const url = `${getConfig().drupal}/${getConfig().path}/node/homepage/${
        props.uuid
      }`
      const params = "resourceVersion=rel:working-copy"
      const { data: nodeData, error: nodeError } = await fetchNode(
        `${url}?${params}`
      )
      setData(nodeData)
      setError(nodeError)
      setLoading(false)
    })()
  }, [props.uuid])

  if (loading) {
    return <span>Loading ...</span>
  }

  if (!props.uuid) {
    return <span>No ID provided!</span>
  }

  if (
    !props.uuid.match(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    )
  ) {
    return <span>Invalid ID provided!</span>
  }

  if (error) {
    return <span>{error}</span>
  }

  return (
    <Layout meta={meta} data={data} menus={menus} blocks={blocks}>
      <Seo meta={meta} data={data} />
    </Layout>
  )
}

export default IndexPage
